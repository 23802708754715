// Vue3
import { ViteSSG } from 'vite-ssg';

// Application
import App from '@/views/App.vue';

// Styles
import '@/assets/scss/style.scss';
(() => import('@/assets/scss/fontfaces.scss'))();
//(() => import('@/assets/scss/fontawesome.scss'))();

// Router
import { routes } from './router/routes';

export const createApp = ViteSSG(
  App,
  {
    routes,
    base: import.meta.env.BASE_URL,
  },
  () => {
  },
);
